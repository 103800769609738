import React from 'react'
import Helmet from 'react-helmet'
import Placeholder from '../components/placeholder'
import ResponsabileReparto from '../components/responsabile-reparto'
import Breadcrumbs from '../components/breadcrumbs'
import SelectServizi from '../components/select-servizi'
import DoveSiamo from '../components/dove-siamo'
import Layout from "../components/layout"
import DocumentiUtili from '../components/documenti-utili'
import { graphql } from "gatsby"
import Team from '../components/team'

class CaniGatti extends React.Component {

  render() { 
    const servizi = this.props.data.allContentfulSchedaServizio.edges;
    const intro = this.props.data.contentfulContenutoGenerico;
    const responsabile =  this.props.data.responsabile;
    const breadcrumb = [{"title":"Reparti","link":"/reparti/"},{"title":"Clinica dei piccoli animali"}];

    return (
      <Layout location={this.props.location}>
        <div className="container">
          <Helmet title="Clinica dei piccoli animali"/>
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              <section id="servizi-cani-gatti" className="section"  aria-labelledby="region1">
                <h1 id="region1" className="section__title">Clinica dei piccoli animali</h1>
                {!!intro && 
                  <div dangerouslySetInnerHTML={{ __html: intro.abstract.childMarkdownRemark.html }} >
                  
                  </div>
                }
                <h2><strong>Servizi clinici</strong></h2>

                <div className="row flex-wrap ">
                  {servizi.map((item,index) => {
                    
                      let dataserv = {
                        "title": item.node.titolo,
                        "link": "/cani-e-gatti/"+item.node.friendlyUrl+"/"
                        };
                      
                        return ( 
                          <div key={index} className="col-6">
                            <Placeholder data={dataserv}/>
                          </div>
                        ) 
                      
                  })}
                </div>
              </section>
 
            </div>
            <div className="col-md-4">
              <SelectServizi/>
              {!!responsabile &&
                <ResponsabileReparto data={responsabile}/>
              }
              {!!intro.titoloSpallaDestra &&
                <div className="webcontent">
                  <h2 className="webcontent__title">{intro.titoloSpallaDestra}</h2>
                    <div className="webcontent__abstract breakword" dangerouslySetInnerHTML={{ __html: intro.testoSpallaDestra.childMarkdownRemark.html }} >
                    
                    </div>
                </div>
              }
              {intro.team && 
                <Team data={intro} elenco={false}/>
              }
              {/* <Visite btn={{"link":"/staff?specie=1#elenco"}}/> */}
              {!!intro.attachment &&
                <DocumentiUtili data={intro.attachment}/>
              }

              {/* <Emergenza/> */}
              <DoveSiamo/>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

}

export default CaniGatti;


export const query = graphql`
query CaniGattiQuery{
  allContentfulSchedaServizio(sort: {fields: [titolo]}, filter: {specie: {in: "Cani e gatti"}, node_locale: {eq: "it-IT"} }) {
    edges {
     node {
      id
      titolo
      friendlyUrl
      specie
    	}
    }
  }
  contentfulContenutoGenerico (contentful_id: {eq: "4G2xoHi2GIWoGYkQUoGgMO"}) {
  	abstract {
      childMarkdownRemark {
        html
      }
    }
    contentful_id
    titoloSpallaDestra
    testoSpallaDestra {
      childMarkdownRemark {
        html
      }
    }
    team: staff {
      nome
      cognome
      friendlyUrl
    }
    attachment {
      title
      file {
        url
      }
    }
  }
  responsabile: contentfulResponsabileReparto(reparti: {in: "Clinica dei piccoli animali"}, node_locale: {eq: "it-IT"}) {
    nome
    cognome
    schedaStaff {
      friendlyUrl
    }
    foto {
      file {
        url
      }
    }
  }
  serviziJson {
    diagnostici{
      horizontal {
        title
        subtitle
        img
        link
      }
    }
  }
}
`